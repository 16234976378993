import React from 'react'
import poster from '../images/Dan_Rather_Homepage_Poster.jpg'

class BackgroundVideo extends React.Component {
  constructor(props) {
    super();
    this.state = {
      isMobile: true,
    }
  }

  componentDidMount = () => {
    if (typeof window !== `undefined`) {
      console.log(window.outerWidth);
      let mobile = window.outerWidth < 1200 ? true : false;
      this.setState({ isMobile: mobile });
    }
  }

  render = () => {
    return (
      <div id="background-video">
        <video
          src={(this.state.isMobile) ? '' : 'https://player.vimeo.com/external/491383454.sd.mp4?s=f77111e28e58f1dc87743ecd9eeca185e17ff05c&profile_id=164' }
          poster={poster}
          height="720"
          width="1280"
          loop
          muted
          autoPlay>
        </video>
        <div className="container-lg">
          <div className="row">
            <div className="col-sm-12">
              <div className="home-hero-textbox-wrapper">
                <div className="home-hero-textbox">
                  <h1 className="semibold">The Dan Rather Medals for News and Guts</h1>
                  <p className="subheader serif">Awards honoring reporting as well as exceptional content</p>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default BackgroundVideo
