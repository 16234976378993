import React from "react"
import { graphql, StaticQuery } from 'gatsby'
import Img from "gatsby-image"

const Montage = () => (
  <StaticQuery
    query={graphql`
      query {
        m1: file(relativePath: { eq: "montage/montage1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        m2: file(relativePath: { eq: "montage/montage2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        m3: file(relativePath: { eq: "montage/montage3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        m4: file(relativePath: { eq: "montage/montage4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        m5: file(relativePath: { eq: "montage/montage5.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        m6: file(relativePath: { eq: "montage/montage6.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        m7: file(relativePath: { eq: "montage/montage7.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        m8: file(relativePath: { eq: "montage/montage8.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        m9: file(relativePath: { eq: "montage/dan-nixon.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        m10: file(relativePath: { eq: "montage/dan-afghanistan.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        m11: file(relativePath: { eq: "montage/montage11.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        m12: file(relativePath: { eq: "montage/Unknown.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <div id="montage">
          <div className="image-wrapper">
            <Img
              fluid={data.m1.childImageSharp.fluid}
              alt="News anchors Tom Brokaw and Dan Rather at a 1990 vigil in Washington. Photograph by Dirck Halstead. Dirck Halstead Photographic Archive, Briscoe Center for American History, The University of Texas at Austin."
              title="News anchors Tom Brokaw and Dan Rather at a 1990 vigil in Washington. Photograph by Dirck Halstead. Dirck Halstead Photographic Archive, Briscoe Center for American History, The University of Texas at Austin."
              style={{ width: `100%`, }}
            />
          </div>
          <div className="image-wrapper">
            <Img
              fluid={data.m2.childImageSharp.fluid}
              alt="Dan Rather at the 1984 Democratic National Convention in San Francisco.  Photograph by Matthew Naythons, Matthew Naythons Photographic Archive, Briscoe Center for American History, The University of Texas at Austin."
              title="Dan Rather at the 1984 Democratic National Convention in San Francisco.  Photograph by Matthew Naythons, Matthew Naythons Photographic Archive, Briscoe Center for American History, The University of Texas at Austin."
              style={{ width: `100%`, }}
            />
          </div>
          <div className="image-wrapper">
            <Img
              fluid={data.m4.childImageSharp.fluid}
              alt="“60 Minutes” producer Philip Scheffler with Dan Rather, undated. From Philip Scheffler Papers, Briscoe Center for American History, The University of Texas at Austin."
              title="“60 Minutes” producer Philip Scheffler with Dan Rather, undated. From Philip Scheffler Papers, Briscoe Center for American History, The University of Texas at Austin."
              style={{ width: `100%`, }}
            />
          </div>
          <div className="image-wrapper">
            <Img
              fluid={data.m3.childImageSharp.fluid}
              alt="Dan Rather at the 1980 Democratic National Convention at Madison Square Garden, New York City. Photograph by Robert McNeely. Robert “Bob” Photographic Archive, Briscoe Center for American History, The University of Texas at Austin."
              title="Dan Rather at the 1980 Democratic National Convention at Madison Square Garden, New York City. Photograph by Robert McNeely. Robert “Bob” Photographic Archive, Briscoe Center for American History, The University of Texas at Austin."
              style={{ width: `100%`, }}
            />
          </div>
          <div className="image-wrapper">
            <Img
              fluid={data.m5.childImageSharp.fluid}
              alt="Dan Rather on assignment with camera crew. Photograph by Dirck Halstead, Dirck Halstead photographic archive, Briscoe Center for American History, The University of Texas at Austin."
              title="Dan Rather on assignment with camera crew. Photograph by Dirck Halstead, Dirck Halstead photographic archive, Briscoe Center for American History, The University of Texas at Austin."
              style={{ width: `100%`, }}
            />
          </div>
          <div className="image-wrapper">
            <Img
              fluid={data.m6.childImageSharp.fluid}
              alt="Dan Rather in West Berlin in 1969. Photograph by Dennis Brack. Dennis Brack Photographic Archive, Briscoe Center for American History, The University of Texas at Austin."
              title="Dan Rather in West Berlin in 1969. Photograph by Dennis Brack. Dennis Brack Photographic Archive, Briscoe Center for American History, The University of Texas at Austin."
              style={{ width: `100%`, }}
            />
          </div>
          <div className="image-wrapper">
            <Img
              fluid={data.m7.childImageSharp.fluid}
              alt="Walter Cronkite and Dan Rather. Photograph of Walter Cronkite and Dan Rather at the 1988 Democratic National Convention in Atlanta. Photograph by Dennis Brack. Dennis Brack Photographic Archive, Briscoe Center for American History, The University of Texas at Austin."
              title="Walter Cronkite and Dan Rather. Photograph of Walter Cronkite and Dan Rather at the 1988 Democratic National Convention in Atlanta. Photograph by Dennis Brack. Dennis Brack Photographic Archive, Briscoe Center for American History, The University of Texas at Austin."
              style={{ width: `100%`, }}
            />
          </div>
          <div className="image-wrapper">
            <Img
              fluid={data.m8.childImageSharp.fluid}
              alt="Charles Kuralt and Dan Rather in Moscow during 1988 Reagan-Gorbachev summit. Photograph by Dennis Brack. Dennis Brack Photographic Archive, Briscoe Center for American History, The University of Texas at Austin."
              title="Charles Kuralt and Dan Rather in Moscow during 1988 Reagan-Gorbachev summit. Photograph by Dennis Brack. Dennis Brack Photographic Archive, Briscoe Center for American History, The University of Texas at Austin."
              style={{ width: `100%`, }}
            />
          </div>
          <div className="image-wrapper">
            <Img
              fluid={data.m9.childImageSharp.fluid}
              alt="Dan Rather interviewing President Richard Nixon in January 1972. @gettyimages"
              title="Dan Rather interviewing President Richard Nixon in January 1972. @gettyimages"
              style={{ width: `100%`, }}
            />
          </div>
          <div className="image-wrapper">
            <Img
              fluid={data.m10.childImageSharp.fluid}
              alt="Dan Rather interviews U.S. Army soldiers in 2001 north of Kabul, Afghanistan. @gettyimages"
              title="Dan Rather interviews U.S. Army soldiers in 2001 north of Kabul, Afghanistan. @gettyimages"
              style={{ width: `100%`, }}
            />
          </div>
          <div className="image-wrapper">
            <Img
              fluid={data.m11.childImageSharp.fluid}
              alt='Dan Rather and the Dalai Lama for the 2007 report "One Man vs. China.” Rather (Dan) Papers, e_rathe_00185, Briscoe Center for American History, The University of Texas at Austin.'
              title='Dan Rather and the Dalai Lama for the 2007 report "One Man vs. China.” Rather (Dan) Papers, e_rathe_00185, Briscoe Center for American History, The University of Texas at Austin.'
              style={{ width: `100%`, }}
            />
          </div>
          <div className="image-wrapper">
            <Img
              fluid={data.m12.childImageSharp.fluid}
              alt="Dan Rather covering Hurricane Carla at KHOU in Houston, TX"
              title="Dan Rather covering Hurricane Carla at KHOU in Houston, TX"
              style={{ width: `100%`, }}
            />
          </div>
        </div>
      )
    }}
  />
)

export default Montage;
